import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";
import { CheckCircle, Info, TriangleAlert, XCircle } from "lucide-react";
const alertVariants = cva(
  ["relative", "w-full", "p-4", "border", "border-l-4", "[&>svg]:absolute", "[&>svg]:left-4", "[&>svg]:top-4"],
  {
    variants: {
      variant: {
        default: "bg-[hsl(51,16%,93%)] border-[hsl(51,16%,80%)] border-l-[hsl(51,16%,60%)] text-[hsl(51,16%,30%)]",
        destructive: "bg-[hsl(51,16%,93%)] border-[hsl(51,16%,80%)] border-l-hausred text-[hsl(51,16%,30%)]",
        success: "bg-[hsl(51,16%,93%)] border-[hsl(51,16%,80%)] border-l-hausspringgreen text-[hsl(51,16%,30%)]",
        warning: "bg-[hsl(51,16%,93%)] border-[hsl(51,16%,80%)] border-l-hausyellow text-[hsl(51,16%,30%)]",
        info: "bg-[hsl(51,16%,93%)] border-[hsl(51,16%,80%)] border-l-hausblue text-[hsl(51,16%,30%)]",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

const icons = {
  default: Info,
  destructive: XCircle,
  success: CheckCircle,
  warning: TriangleAlert,
  info: Info,
}

const iconColors = {
  default: "text-[hsl(51,16%,60%)]",
  destructive: "text-hausred",
  success: "text-hausspringgreen",
  warning: "text-black",
  info: "text-hausblue",
}

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof alertVariants> {
  title?: string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className, variant = "default", title, children, ...props }, ref) => {
    const Icon = icons[variant as keyof typeof icons || "default"]

    return (
      <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props}>
        <Icon className={cn("h-5 w-5", iconColors[variant as keyof typeof iconColors || "default"])} />
        <div className="ml-7 flex flex-col gap-1">
          {title && <h5 className="font-semibold leading-none tracking-tight">{title}</h5>}
          <div className="text-sm [&_p]:leading-relaxed">{children}</div>
        </div>
      </div>
    )
  },
)
Alert.displayName = "Alert"

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (

  <h5
    ref={ref}
    className={cn("mb-1 hauslabel", className)}
    {...props}
  />
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm font-light [&_p]:leading-relaxed", className)}
    {...props}
  />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription, AlertTitle };
